// Import base styles
@import 'QuizBase';

// Import brand-specific styles
@import './brands/WellneeStyles';
@import './brands/LulutoxStyles.scss';
@import './brands/WellarayStyles.scss';
@import './CountdownBanner.scss';

// BF Elements
@import './elements/BfSaleNotice.scss';
@import './elements/FeaturedLogos.scss';

// Interstitial specific styles
@import './interstitial.scss';

//Questions
@import './questions/loader.scss';

// Custom interstitial loader styles
.interstitial-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 32px 0;
  
  .interstitial-loader-text {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: #02122E;
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  
  .interstitial-loader-image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    
    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
  
  .interstitial-loader-lottie {
    width: 100%;
    max-width: 128px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    
    .lottie-container {
      &.lottie-interstitial-loader {
        margin: 0 auto;
        width: 128px !important;
        height: 128px !important;
      }
    }
  }
}
