// Variables
$primary-color: #02122e;
$secondary-color: #f6c26e;
$border-color: #b9cfcc;
$hover-color: #fff1d9;
$active-border-color: #e9af50;

html {
    p {
        margin: 0;
    }
}

.quiz-container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; // Changed from height to min-height
    height: -webkit-fill-available; // For iOS Safari
}

.quiz-header {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    background-color: #fff;
    padding: 16px;
}

.quiz-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.quiz-header-container.center-logo {
    justify-content: center;
}

.quiz-progress-bar-wrapper {
    background-color: #fdd4de;
    height: 4px;
    min-height: 4px;
}

.quiz-progress-bar {
    background-color: #e4469d;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
}

.quiz-progress-bar-wrapper {
    width: 100%;
    height: 4px;
    position: relative;
}

.quiz-progress-bar {
    height: 100%;
    background-color: #e4469d; // Use your brand color here
    transition: width 0.5s ease-in-out; // This line adds the animation
    position: absolute;
    left: 0;
    top: 0;
}

.back-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-button {
    font-size: 18px;
    color: #000;
    background: none;
    border: none;
    cursor: pointer;

    svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
}

.logo {
    max-height: 26px;
}

.progress {
    min-width: 70px;
    font-size: 1rem;
    color: #000;
    text-align: right;
}

.quiz-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
}

.quiz-content {
    position: relative;
    z-index: 1;
}

.quiz-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden; // Prevent horizontal scrolling during animations
}

.quiz-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: var(--quiz-background-image);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
}

.quiz-content {
    position: relative;
    z-index: 1;
}

.question-container {
    max-width: 50%;
    margin: 0 auto;
    margin-top: 120px;
    padding: 0 16px;
    padding-bottom: 100px;

    @media (max-width: 768px) {
        max-width: 90%;
        margin-top: 80px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        margin-top: 20px;
    }

    &.question-age {
        max-width: 100%;
    }

    .single-question-top-subtitle {
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        color: #02122e;
        max-width: 620px;
    }

}

.question-wrapper {
    width: 100%;
}

// Forward transitions
.question-forward-enter {
    opacity: 0;
    transform: translateX(100%);
}

.question-forward-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.question-forward-exit {
    opacity: 1;
    transform: translateX(0%);
}

.question-forward-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0 ease-in, transform 0 ease-in;
}

// Backward transitions
.question-backward-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.question-backward-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.question-backward-exit {
    opacity: 1;
    transform: translateX(0%);
}

.question-backward-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0 ease-in, transform 0 ease-in;
}

.quiz-button {
    width: 100%;
    display: flex;
    color: $primary-color;
    font-weight: 700;
    padding: 0 2rem;
    min-height: 52px;
    font-size: 20px;
    line-height: 1.25rem;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: $secondary-color;
    max-width: 452px;
    border: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.option-button {
    width: 100%;
    max-width: 352px;
    display: flex;
    color: $primary-color;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    align-items: center;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid $border-color;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;

    &:focus-visible {
        outline: none;
    }

    &.active {
        border-color: $active-border-color;
        background-color: $hover-color;
    }
}

.chart-question-container,
.email-question-container,
.info-question-container,
.multiple-question-container,
.single-question-container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.email-input-container {
    flex: 1;
    width: 100%;
    display: flex;
    max-width: 350px;
    flex-direction: column;

    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.email-input {
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
    padding: 15px 10px;
    background-color: #ffffff;
    border: none;
    border-bottom: 2px solid #02122e;
    text-align: left;
    margin-top: 10px;
    box-sizing: border-box;

    &::placeholder {
        text-align: left;
    }

    &:focus {
        outline: none;
        border-bottom-color: $primary-color;
    }
}

.email-label {
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
}

.email-footnote-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-footnote-note {
    color: #54434e;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    margin-left: 0.5rem;
}

.single-question-top-title {
    margin: 0;
    color: $primary-color;
    text-align: center;
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 16px;
    margin-top: 16px;
}

.single-question-top-subtitle {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    color: #02122e;
}

.option-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.option-buttons-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}

.option-buttons-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question-text-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.question-title {
    margin: 20px;
    color: $primary-color;
    text-align: center;
    font-size: 2rem;
    line-height: 38px;
    font-weight: 700;

    @media (max-width: 569px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

.question-subtitle {
    margin: 0;
    color: $primary-color;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
}

.question-subtitle-html {
    padding: 0 20px;
    margin: 0;
    color: $primary-color;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
}

.mt-4 {
    margin-top: 16px;
}

.show-on-desktop {
    display: block;
}

.show-on-mobile {
    display: none;
}

.chart-image {
    max-width: 800px;
}

@media (max-width: 768px) {
    .show-on-desktop {
        display: none;
    }

    .show-on-mobile {
        display: block;
    }
}

.loader-container {
    text-align: center;
    padding: 0 20px;
    font-family: Arial, sans-serif;
}

.loader-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.loader-container .highlight {
    color: #d86ba5;
}

.analyzing-text {
    margin: 10px 0;
    font-size: 16px;
    color: #6D0C3D;
}

.loader-progress-bar {
    width: 100%;
    background-color: #fff;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    margin: 10px 0;
    border: 1px solid #f6c4e0;
}

.loader-progress {
    background-color: #E4469D;
    height: 100%;
    transition: width 0.1s ease;
}

.before-after-single {
    position: relative;
    display: inline-block;
    margin-top: 20px;
}

.before-after-single img {
    width: 100%;
    max-width: 300px;
    /* Adjust based on your layout */
    border-radius: 8px;
}

.caption {
    position: absolute;
    bottom: 10px;
    width: 50%;
    background-color: rgba(123, 43, 70, 0.8);
    color: #fff;
    padding: 5px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
}

.caption.before {
    left: 0;
}

.caption.after {
    right: 0;
}

.banner-progress-bar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.banner-progress-bar-steps {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.banner-progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
}

.step-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s;
    border: 1px solid #FDD4DE;
}

.banner-progress-step.active .step-circle {
    background-color: #E4469D;
    color: #fff;
}

.banner-progress-step::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 50%;
    width: 100%;
    height: 4px;
    background-color: #FDD4DE;
    z-index: -1;
}

.banner-progress-step.active::after {
    background-color: #E4469D;
}

.banner-progress-step:first-child::after {
    display: none;
}

.banner-progress-step:last-child .step-circle {
    background-color: #FDD4DE;
}


.option-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.option-left {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Adjust this value to control spacing between icon and text */
}

.option-right {
    margin-left: auto;
}

.option-button.active .option-left svg path {
    stroke: #e4469d;
}

.lottie-container {
    position: relative;
    width: 200px; // Adjust this to control the container size
    height: 200px; // Adjust this to control the container size
    display: flex;
    align-items: center;
    justify-content: center;

    // This will make the animation take up the full container space
    >div {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        transform: scale(1.5); // Adjust this 
    }
}

.gender-options {
    display: flex;
}

.gender-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0 5px 5px 5px;
    transition: background-color 0.3s;
    display: flex;
    background-color: white;
}

.gender-option:hover {
    background-color: #f0f0f0;
}

.gender-emoji {
    font-size: 24px;
    margin-right: 10px;
}

.gender-label {
    font-size: 18px;
    font-weight: bold;
}

.gender-arrow {
    margin-left: auto;
    font-size: 18px;
}

.gender-options-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 32px;
}

.gender-option-column {
    flex: 1;
    max-width: 300px;
}

.gender-option-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.gender-portrait {
    width: 100%;
    height: auto;
}

.gender-option {
    width: 100%;
}

.age-options {
    display: flex;
}

.age-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0 5px 5px 5px;
    transition: background-color 0.3s;
    display: flex;
    background-color: white;
}

.age-option:hover {
    background-color: #f0f0f0;
}

.age-emoji {
    font-size: 24px;
    margin-right: 10px;
}

.age-label {
    font-size: 18px;
    font-weight: bold;
}

.age-arrow {
    margin-left: auto;
    font-size: 18px;
}

.age-options-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 32px;
}

.age-option-column {
    flex: 1;
    max-width: 300px;
}

.age-option-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.age-portrait {
    width: 100%;
    height: auto;
}

.age-option {
    width: 100%;
    background-color: #F5F5F5;
}

.age-options-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 16px;
    margin-top: 32px;
}

.age-option-column {
    width: 100%;
}

@media (max-width: 768px) {
    .age-options-wrapper {
        grid-template-columns: repeat(2, 1fr); // Changes to 2 columns on mobile
        gap: 16px; // Slightly smaller gap on mobile
    }
}