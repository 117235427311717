// Wellaray-specific variables
$wellaray-bg: #fbe7ef;
$hover-button: #EFFBD8;
$primary-color: #592022;
$secondary-color: #f05365;
$border-color: #b9cfcc;
$hover-color: #fff1d9;
$active-border-color: #e9af50;


.quiz-container.wellaray {
    .quiz-background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../../public/images/lulutox-desktop-bg.png');
        z-index: -1;

        @media (max-width: 768px) {
            background-image: url('../../../public/images/lulutox-mobile-bg.png');
            background-size: cover;
            background-position: 50%, 50%, 100%;
            background-repeat: no-repeat;
            background-attachment: fixed;

        }
    }

    color: #000;
    background-attachment: fixed;
    min-height: 100vh;


    .option-button {
        width: 100%;
        max-width: 352px;
        display: flex;
        color: $primary-color;
        padding: 20px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        align-items: center;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid $border-color;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 1);
        cursor: pointer;

        &.active {
            border-color: $active-border-color;
            background-color: $hover-color;
        }
    }

    .q-cta-button {
        background-color: #65cd57;
        color: #fff;
        font-size: 20px;
    }
}

.quiz-container-test.wellaray {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.wellaray-tt {
        justify-content: flex-start;

        .question-container {
            max-width: 100%;
        }


        .single-question-top-title {
            color: #592022;
            font-size: 32px;

            @media (max-width: 768px) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .single-question-top-subtitle {
            color: #592022;
            font-size: 20px;
        }

        .age-option {
            background-color: #fff;
            color: #592022;
        }

        .featured-logos .title {
            color: #592022;
        }

        .q-cta-button {
            border-radius: 50px;
        }

        .question-subtitle-html {
            color: #592022;
        }
    }

    .quiz-background {
        background: #ffedee;
    }

    .option-button {
        width: 100%;
        max-width: 352px;
        display: flex;
        color: $primary-color;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        align-items: center;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid $border-color;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 1);
        cursor: pointer;


        &.active {
            border-color: $secondary-color;
            color: $secondary-color;
        }

        &.active .option-content .option-right svg path {
            fill: $secondary-color;
        }

        &.active .option-content .option-left svg path {
            stroke: $secondary-color;
        }
    }

    .quiz-button {
        background-color: #00C249;
        color: #fff;
    }

    .q-cta-button {
        max-width: 352px;
    }

    .question-container {
        margin-top: 64px;
        padding-bottom: 16px;

        @media (max-width: 768px) {
            max-width: 90%;
            margin-top: 80px;
        }

        @media (max-width: 480px) {
            max-width: 100%;
            margin-top: 20px;
        }
    }

    .quiz-header-banner {
        background: #470314;
        color: #fff;
        width: 100%;
    }

    .quiz-header-banner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .banner-header-container {
        padding: 8px 0;
        text-align: center;

        p {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .logo-banner {
        margin: 24px 0;
    }

    .banner-progress-bar-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
    }

    .banner-progress-bar-steps {
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
    }

    .banner-progress-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex: 1;
    }

    .step-circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
        color: #333;
        transition: background-color 0.3s;
        border: 1px solid #FDD4DE;
    }

    .banner-progress-step.active .step-circle {
        background-color: $secondary-color;
        color: #fff;
    }

    .banner-progress-step::after {
        content: '';
        position: absolute;
        top: 12px;
        right: 50%;
        width: 100%;
        height: 4px;
        background-color: #FDD4DE;
        z-index: -1;
    }

    .banner-progress-step.active::after {
        background-color: $secondary-color;
    }

    .banner-progress-step:first-child::after {
        display: none;
    }

    .banner-progress-step:last-child .step-circle {
        background-color: #FDD4DE;
    }

    .question-title {
        margin: 20px;
        color: $primary-color;
        text-align: center;
        font-size: 2rem;
        line-height: 38px;
        font-weight: 700;

        @media (max-width: 569px) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    .loader-progress {
        background-color: #f05365;
        height: 100%;
        transition: width 0.1s ease;
    }

}