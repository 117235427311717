/* SummaryQuestion.scss */

.summary-container {
  font-family: 'Inter', sans-serif;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

// Weight Loss Projection Section
.summary-title {
  font-size: 32px;
  font-weight: 700;
  color: #02122E;
  text-align: center;
  margin-bottom: 24px;

  @media(max-width: 767px) {
    margin: 0 0 16px 0;
    font-size: 24px;
  }
}

// Chart Section
.summary-chart-container {
  display: flex;
  flex-direction: column;
  gap: 16px; // Match Figma spacing
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.summary-container-inner {
  max-width: 400px;
  margin: 0 auto;
}

.summary-chart-card {
  position: relative;
}

.summary-chart-image {
  width: 100%;
  transform: scale(1.15)
}

.first-bubble{
  box-shadow: 0px 16.373px 32.746px 0px rgba(0, 0, 0, 0.16);;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
  position: absolute;
  left: 11%;
  top: 23%;
  font-weight: 700;
}

.second-bubble {
  box-shadow: 0px 16.373px 32.746px 0px rgba(0, 0, 0, 0.16);;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
  position: absolute;
  left: 43%;
  top: 54%;
  font-weight: 700;
}

.third-bubble {
  box-shadow: 0px 16.373px 32.746px 0px rgba(0, 0, 0, 0.16);;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
  position: absolute;
  left: 84%;
  top: 47%;
  font-weight: 700;
}

.summary-chart {
  width: 100%;
  height: 200px; // Fixed height to match Figma
  position: relative;
  overflow: hidden;
}

.summary-chart-grid {
  position: relative;
  width: 100%;
  height: 100%;
}

.summary-chart-horizontal-lines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.summary-chart-line {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #E6E7EA; // Exact line style from Figma
  margin-bottom: 33px; // Exact spacing from Figma
  
  &:last-child {
    margin-bottom: 0;
  }
}

.summary-chart-path {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.summary-chart-svg {
  width: 100%;
  height: 100%;
  overflow: visible; // Allow path to extend beyond container if needed
}

.summary-chart-point {
  position: absolute;
  width: 16px; // Exact size from Figma
  height: 16px;
  background-color: #65CD57; // Exact color from Figma
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15); // Subtle shadow for depth
}

.summary-chart-tooltip {
  position: absolute;
  top: -36px; // Adjusted position from Figma
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 8px;
  padding: 8px; // Adjusted padding for better appearance
  font-size: 13px; // Exact font size from Figma
  font-weight: 700;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16); // Exact Figma shadow
  white-space: nowrap;
  z-index: 3; // Ensure tooltip is above points
  min-width: 45px; // Fixed width for all tooltips
  text-align: center; // Center the weight text
}

.summary-chart-labels {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px; // Exact font size from Figma
  color: #02122E;
  opacity: 0.4; // Exact opacity from Figma
}

.summary-chart-y-label, .summary-chart-x-label {
  font-size: 13px;
  font-weight: 400; // Regular weight from Figma
}

// BMI Card
.summary-bmi-card {
  background: #fff;
  border: 1px solid #E6E7EA;
  border-radius: 16px;
  padding: 20px;
  margin-top: 8px;
  box-shadow: 0px 16px 32px 0px rgba(228, 70, 157, 0.08);
}

.summary-bmi-title {
  font-size: 16px;
  font-weight: 400;
  color: #00264C;
  text-align: center;
  margin-bottom: 16px;
  
  .bmi-measurements {
    display: inline;
  }
  
  @media (max-width: 767px) {
    .bmi-measurements {
      display: block;
      margin-top: 4px;
      white-space: normal;
      word-break: break-word;
    }
  }
}

.summary-comparison-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.summary-comparison-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 16px;
}

.summary-comparison-image {
  width: 88px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.summary-comparison-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.summary-comparison-label {
  font-size: 14px;
  font-weight: 700;
  color: #00264C;
}

.summary-comparison-detail {
  font-size: 12px;
  color: #00264C;
}

// Product Recommendation
.summary-recommendation-title {
  font-size: 32px;
  font-weight: 700;
  color: #02122E;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;

  @media(max-width: 768px) {
    font-size: 24px;
    margin: 16px 0;
  }
}

.summary-product-card {
  background: #FAF0C9;
  font-family: 'Inter', sans-serif;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.summary-product-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.summary-product-logo {
  font-size: 24px;
  font-weight: 700;
  color: #02122E;
}

.lulutox-logo {
  img {
    width: 140px;
  }

}

.summary-product-tagline {
  font-size: 16px;
  text-align: center;
  color: #02122E;
}

.summary-product-image {
  width: 100%;
  object-fit: cover;
  max-width: 252px;
  border-radius: 8px;
}

.summary-product-description {
  font-size: 16px;
  text-align: center;
  color: #02122E;
}

.summary-product-pricing {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.summary-product-original-price {
  font-size: 16px;
  color: #02122E;
  text-decoration: line-through;
  font-weight: 400;
}

.summary-product-sale-price {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color: #02122E;
}

.summary-product-quantity {
  font-size: 16px;
  color: #02122E;
}

.summary-product-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.summary-product-button {
  width: 100%;
  padding: 16px;
  background: linear-gradient(to bottom, #00C249, #25741B);
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  
  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.summary-product-guarantee {
  font-size: 14px;
  font-weight: 700;
  color: #02122E;
}

// Reviews Section
.summary-reviews-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 32px;

  @media(max-width: 767px) {
    margin-top: 16px;
  }
}

.summary-rating-card {
  background: #fff;
  border: 1px solid #E6E7EA;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-rating-label {
  font-size: 18px;
  color: #00264C;
  text-align: center;
}

.summary-rating-value {
  font-size: 48px;
  color: #00264C;
  text-align: center;
}

.summary-review-card {
  background: #fff;
  border: 1px solid #E6E7EA;
  border-radius: 16px;
  padding: 24px;
}

.summary-review-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.summary-review-user-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.summary-review-name {
  font-size: 14px;
  font-weight: 700;
  color: #00264C;
}

.summary-review-stars {
  display: flex;
}

.summary-review-star {
  color: #E6E7EA;
  font-size: 18px;
  margin-right: 2px;
  
  &.filled {
    color: #FFAA00;
  }
}

.summary-review-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.summary-review-date, .summary-review-verified {
  font-size: 14px;
  color: #00264C;
}

.summary-review-divider {
  height: 1px;
  background: #E6E7EA;
  margin: 16px 0;
}

.summary-review-text {
  font-size: 16px;
  line-height: 1.4;
  color: #02122E;
}

// Legal Disclaimer
.summary-disclaimer {
  font-size: 10px;
  line-height: 1.2;
  color: #02122E;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 32px;
}

// Responsive styles for mobile
@media (max-width: 767px) {
  .summary-comparison-container {
    flex-direction: row;
  }
  
  .summary-product-pricing {
    flex-wrap: wrap;
    justify-content: center;
  }
}
