.countdown-banner {
    background-color: #0B4B2D;
    color: white;
    padding: 8px 16px;
    text-align: center;
    width: 100%;

    .banner-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 8px;

        // Left block
        .countdown-group {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .sale-text {
            color: #ED0006;
            font-weight: bold;
        }

        .is-here {
            color: white;
            font-weight: bold;
        }

        .timer {
            background-color: #ED0006;
            padding: 2px 4px;
            border-radius: 4px;
            font-weight: bold;
        }

        // Right block
        .stock-info-wrapper {
            width: 100vw; // Full viewport width
            margin-left: calc(-50vw + 50%); // Center the element
            margin-right: calc(-50vw + 50%);
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            margin-top: 4px;
            padding-top: 8px;
        }

        .stock-info {
            display: flex;
            gap: 16px;
            width: 100%;
            justify-content: center;
            font-weight: bold;
            font-size: 12px;

            .stock-level,
            .price-tag {
                display: flex;
                align-items: center;
                gap: 4px;

                .icon {
                    margin-right: 0px;
                }

                .low {
                    color: #ED0006;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .banner-content {
            flex-wrap: nowrap;
            justify-content: space-around;
            max-width: 1200px;
            margin: 0 auto;

            .stock-info-wrapper {
                width: auto;
                margin: 0;
                border-top: none;
                padding-top: 0;
            }

            .stock-info {
                width: auto;
                font-size: 14px;

                .price-tag {
                    .icon {
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}