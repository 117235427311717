// Wellnee-specific variables
$wellnee-primary: #00C249;
$wellnee-bg: #eaf8e9;
$hover-button: #EFFBD8;

.quiz-container.wellnee {
    font-family: 'Montserrat', sans-serif;
    background-color: #eaf8e9;
    color: #02122e;

    .quiz-background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('https://cdn.prod.website-files.com/6512bf8f24595224b11e80e2/659fcde43fd2e7e0ade5055c_arrow-to-knee%20(1).png');
        z-index: 1;

        @media (max-width: 768px) {
            background-image: url('https://cdn.prod.website-files.com/6512bf8f24595224b11e80e2/659fd2e8f976af7342a7b20d_knee-mobile%20(1).png');
            background-size: cover;
            background-position: 50%, 50%, 100%;
            background-repeat: no-repeat;
            background-attachment: fixed;

        }
    }

    .option-button {
        width: 100%;
        max-width: 352px;
        display: flex;
        color: $primary-color;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        align-items: center;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid $border-color;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.8);
        cursor: pointer;

        &:hover {
            background-color: $hover-button;
        }

        &.active {
            border-color: $active-border-color;
            background-color: $hover-color;
        }
    }

    .single-question-top-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
    }

    .quiz-progress-bar-wrapper {
        background-color: #eefbd8;
        height: 4px;
        min-height: 4px;
    }

    .quiz-progress-bar {
        background-color: #65cd57;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 100%;
    }

    .quiz-progress-bar-wrapper {
        width: 100%;
        height: 4px;
        position: relative;
    }

    .q-cta-button {
        background-color: #65cd57;
        color: #fff;
        font-size: 20px;
    }
}