// Lulutox-specific variables
$lulutox-bg: #fbe7ef;
$hover-button: #EFFBD8;


.quiz-container.lulutox {
    .quiz-background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../../public/images/lulutox-desktop-bg.png');
        z-index: -1;

        @media (max-width: 768px) {
            background-image: url('../../../public/images/lulutox-mobile-bg.png');
            background-size: cover;
            background-position: 50%, 50%, 100%;
            background-repeat: no-repeat;
            background-attachment: fixed;

        }
    }

    color: #000;
    background-attachment: fixed;
    min-height: 100vh;


    .option-button {
        width: 100%;
        max-width: 352px;
        display: flex;
        color: $primary-color;
        padding: 20px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        align-items: center;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid $border-color;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 1);
        cursor: pointer;

        &:focus-visible {
            outline: none;
        }

        &.active {
            border-color: $active-border-color;
            background-color: $hover-color;
        }
    }

    .q-cta-button {
        background-color: #65cd57;
        color: #fff;
        font-size: 20px;
    }
}

.quiz-container-test.lulutox.gender-test {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.quiz-container-test.lulutox.gender-test-2 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .age-top-heading {
        margin-top: 0;

        @media (max-width: 768px) {
            margin-bottom: 8px;
        }

    }

    .single-question-top-title {
        font-size: 40px;
        line-height: 48px;

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .question-title {
        font-size: 40px;
        line-height: 48px;

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .fade-image {
        animation: fadeIn 0.5s ease-in;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .before-after-single {
        min-height: 400px;
    }

    .back-button {
        font-size: 16px;
    }

    .option-button {
        width: 100%;
        max-width: 352px;
        display: flex;
        color: $primary-color;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        align-items: center;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        border: none;
        justify-content: space-between;
        background-color: #F5F5F5;
        cursor: pointer;

        &.active {
            border-color: #E4469D;
            color: #E4469D;
            border: 2px solid #E4469D;

            .option-description-areas-to-improve {
                color: #E4469D;
            }
        }

        &.active.option-areas-to-improve {
            background-color: #fdf0f7;
        }

        &.option-areas-to-improve {
            padding: 0;
        }
    }

    .lottie-container.lottie-scales {
        margin-bottom: 24px;
        margin-top: 12px;
    }

    .lottie-container.lottie-tired {
        margin-bottom: 32px;
        margin-top: 12px;
    }

    .age-top-title {
        font-size: 20px;
        color: #02122EA3;
    }

    .lottie-container.lottie-immune {
        margin-bottom: 40px;
        margin-top: 12px;
    }

    .lottie-container.lottie-tea {
        margin-bottom: 40px;
        margin-top: 12px;
    }

    .lottie-container.lottie-stomach {
        margin-bottom: 30px;
    }

    .lottie-container.lottie-balloon {
        margin-bottom: 50px;
        margin-top: 32px;
    }

    .option-content-areas-to-improve {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .option-left-areas-to-improve {
        flex: 1;
        flex-direction: column;
        align-items: unset;
        margin-right: 16px;
        margin-left: 16px;
    }

    .option-right-areas-to-improve {
        flex: 0 0 auto;
        /* This prevents the right column from growing */
        /* or you can set a specific width like: */
        /* width: 48px; */
    }

    .option-title-row {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .option-description-areas-to-improve {
        font-size: 14px;
        color: #02122E;
        font-weight: 500;
        margin-top: 8px;
    }

    .option-right {
        display: flex;
        align-items: center;
    }
}


.quiz-container-test.lulutox {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .quiz-background {
        background: #FAF4FC;
    }

    .option-button {
        width: 100%;
        max-width: 352px;
        display: flex;
        color: $primary-color;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        align-items: center;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid $border-color;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 1);
        cursor: pointer;

        &.active {
            border-color: #E4469D;
            color: #E4469D;
        }
    }

    .quiz-button {
        background-color: #00C249;
        color: #fff;
    }

    .q-cta-button {
        max-width: 352px;
    }

    .question-container {
        margin-top: 64px;
        padding-bottom: 16px;

        @media (max-width: 768px) {
            max-width: 90%;
            margin-top: 80px;
        }

        @media (max-width: 480px) {
            max-width: 100%;
            margin-top: 20px;
        }
    }

    .quiz-header-banner {
        background: #470314;
        color: #fff;
        width: 100%;
    }

    .quiz-header-banner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .banner-header-container {
        padding: 8px 0;
        text-align: center;

        p {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .logo-banner {
        margin: 24px 0;
    }
}

.quiz-container-test.lulutox.gender-test-2 {
    .quiz-background {
        background-color: #ffffff;
    }
}

/* Styles for the German Lulutox Interstitial */
.interstitial-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FFFFFF;
  
  /* Header with logo */
  .interstitial-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 80px;
    border-bottom: 1px solid #E6E7EA;
    
    .interstitial-logo {
      height: 40px;
    }
  }
  
  /* Custom progress bar for interstitial */
  .quiz-progress-bar-wrapper {
    width: 100%;
    height: 12px;
    background-color: #F2F2F2;
    position: relative;
    margin: 0;
    overflow: hidden;
    
    .quiz-progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: linear-gradient(90deg, #00C249 0%, #25741B 100%);
      transition: width 0.3s ease-in-out;
    }
  }
  
  
  /* Main content */
  .interstitial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 32px;
    flex: 1;
    position: relative; /* Add position relative to contain absolute children */
    
    /* Make the TransitionGroup take full width for proper layout */
    .react-transition-group {
      width: 100%;
      position: relative;
    }
    
    .interstitial-title {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.2;
      color: #02122E;
      text-align: center;
      margin: 0 0 32px 0;
    }
    
    .interstitial-subtitle {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      color: #02122E;
      text-align: center;
      max-width: 600px;
    }
    
    .interstitial-options {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      max-width: 400px;
      
      .interstitial-option-button {
        background: linear-gradient(to bottom, #00C249, #25741B);
        color: #FFFFFF;
        font-family: 'Public Sans', sans-serif;
        font-weight: 700;
        font-size: 18px;
        padding: 16px;
        border-radius: 4px;
        border: none;
        text-align: center;
        cursor: pointer;
        letter-spacing: -0.4px;
        line-height: 1.44;
        width: 100%;
        max-width: 400px; /* Fixed max-width to prevent width changes */
        box-sizing: border-box; /* Ensure padding doesn't affect width calculation */
        
        &:hover {
          opacity: 0.9;
        }
      }
    }
    
    /* Gender question styling */
    .interstitial-gender-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 600px;
    }
    
    .interstitial-gender-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 32px;
      width: 100%;
    }
    
    .interstitial-gender-options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 8px;
      width: 100%;
      max-width: 400px;
      
      @media (max-width: 600px) {
        align-items: center;
        gap: 8px;
      }
    }
    
    .interstitial-gender-option {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      align-items: center;
      flex: 1;
      width: 100%;
      
      @media (max-width: 600px) {
        width: 343px;
        max-width: 100%;
      }
    }
    
    .interstitial-gender-image-container {
      width: 100%;
      background: linear-gradient(to bottom, #00C249, #25741B);
      border-radius: 4px;
      padding: 16px 16px 0;
      cursor: pointer;
      margin-bottom: 8px;
      
      &:hover {
        opacity: 0.9;
      }
    }
    
    .interstitial-gender-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      display: block;
    }
    
    .interstitial-gender-label {
      font-family: 'Public Sans', sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.44;
      letter-spacing: -0.22%;
      color: #02122E;
      text-align: center;
      margin: 0;
    }
    
    /* Info question styling for testimonial screen */
    .interstitial-info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
    
    .interstitial-info-title {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 1.25;
      color: #02122E;
      text-align: center;
      margin: 0 0 16px 0;
      
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    
    .interstitial-info-image-container {
      width: 100%;
      margin: 16px 0;
      display: flex;
      justify-content: center;
      
      img {
        width: 100%;
        max-width: 600px;
        height: auto;
        border-radius: 4px;
      }
    }
    
    .interstitial-info-description {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.375;
      color: #02122E;
      text-align: left;
      margin-bottom: 24px;
    }
    
    .interstitial-info-button {
      background: linear-gradient(to bottom, #00C249, #25741B);
      color: #FFFFFF;
      font-family: 'Public Sans', sans-serif;
      font-weight: 700;
      font-size: 18px;
      padding: 16px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      width: 100%;
      max-width: 400px;
      letter-spacing: -0.4px;
      line-height: 1.44;
      
      &:hover {
        opacity: 0.9;
      }
      
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    
    /* Input question styling */
    .interstitial-input-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 600px;
    }
    
    .interstitial-input-title {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.2;
      color: #02122E;
      text-align: center;
      margin-bottom: 16px;
      
      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
    
    .interstitial-input-subtitle {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.444;
      color: #02122E;
      text-align: center;
      margin-bottom: 32px;
    }
    
    .interstitial-input-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      gap: 16px;
    }
    
    .interstitial-input-group {
      width: 100%;
    }
    
    .interstitial-input-label {
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: #02122E;
      margin-bottom: 12px;
      display: block;
    }
    
    .interstitial-input-field {
      width: 100%;
      
      &.has-error {
        .interstitial-input-wrapper {
          .interstitial-input {
            border-color: #EC0B43;
          }
        }
      }
    }
    
    .interstitial-input-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      
      .interstitial-input-unit {
        position: absolute;
        right: 16px;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #02122E;
      }
    }
    
    .interstitial-input {
      width: 100%;
      height: 56px;
      border: 2px solid #00C249;
      border-radius: 4px;
      padding: 0 16px;
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      color: #02122E;
      
      &:focus {
        outline: none;
        border-color: #25741B;
      }
      
      &::placeholder {
        color: rgba(2, 18, 46, 0.5);
      }
    }
    
    .interstitial-input-error {
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      color: #EC0B43;
      margin: 8px 0 0 0;
    }
    
    .interstitial-input-button {
      background: linear-gradient(to bottom, #00C249, #25741B);
      color: #FFFFFF;
      font-family: 'Public Sans', sans-serif;
      font-weight: 700;
      font-size: 18px;
      padding: 16px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      width: 100%;
      letter-spacing: -0.4px;
      line-height: 1.44;
      
      &:hover:not(:disabled) {
        opacity: 0.9;
      }
      
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    
  }
  
  /* Question transition animations */
  .question-forward-enter {
    transform: translateX(100%);
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .question-forward-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms ease-in-out;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .question-forward-enter-done {
    width: 100%;
    max-width: 600px;
  }
  
  .question-forward-exit,
  .question-forward-exit-active {
    opacity: 0; /* Immediately hide the exiting card */
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: none; /* No transition for exit */
  }
  
  /* Ensure spacing for the transition container */
  .transition-group-container {
    width: 100%;
    position: relative;
    min-height: 400px; /* Set a minimum height to prevent content jumping */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  /* Make sure the transition group is properly centered */
  .react-transition-group {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  
  @media (max-width: 768px) {
    .interstitial-header {
      padding: 16px;
    }
    
    .interstitial-content {
      padding: 40px 16px;
      
      .interstitial-title {
        font-size: 32px;
      }
    }
  }
}
