.bf-sale-element {
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  &.wrapper {
    position: relative;
    border-radius: 0.5rem;
    padding: 8px 16px 8px 16px;
    width: auto;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      padding: 8px 12px;
    }

    p {
      padding: 0 0 0 6px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 20px;
      color: #fff;

      span {
        color: #EC0B43;
      }
    }
  }

  .arrow-up {
    position: absolute;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid black;

    &.left {
      left: 10%;
    }

    &.right {
      right: 10%;
    }
  }
}