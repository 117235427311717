// Interstitial-specific styles for transitions
.interstitial-container {
  .transition-group-container {
    position: relative;
    overflow: hidden;
  }

  .react-transition-group {
    position: relative;
    width: 100%;
  }

  // Forward transitions
  .question-forward-enter {
    opacity: 0;
    transform: translateX(100%);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .question-forward-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
    width: 100%;
  }

  .question-forward-exit {
    opacity: 1;
    transform: translateX(0%);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .question-forward-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0 ease-in, transform 0 ease-in;
    width: 100%;
  }

  // Backward transitions
  .question-backward-enter {
    opacity: 0;
    transform: translateX(-100%);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .question-backward-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
    width: 100%;
  }

  .question-backward-exit {
    opacity: 1;
    transform: translateX(0%);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .question-backward-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0 ease-in, transform 0 ease-in;
    width: 100%;
  }
  
  .interstitial-single-container {
    width: 100%;
    max-width: 600px; // Match option button width
    margin: 0 auto;
    text-align: center;
  }
  
  .interstitial-title {
    text-align: center;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  
  .interstitial-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .interstitial-option-button {
    width: 100%;
    max-width: 352px;
    display: flex;
    color: #02122e;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #b9cfcc;
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
    
    &:hover {
      background-color: #fff1d9;
    }
    
    &:focus-visible {
      outline: none;
    }
  }
}

// Fix BMI measurements line break on mobile
.bmi-measurements {
  @media (max-width: 767px) {
    display: block;
    margin-top: 4px;
    white-space: normal;
    word-break: break-word;
  }
}
