.interstitial-study-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 0;
  
  .interstitial-study-info-title {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.333;
    text-align: center;
    color: #02122E;
    margin: 0 0 16px 0;
  }
  
  .interstitial-study-info-image-container {
    width: 100%;
    margin-bottom: 24px;
    
    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
  
  .interstitial-study-info-text {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .interstitial-study-info-description {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 1.375;
    color: #02122E;
    white-space: pre-line;
  }
  
  .interstitial-study-info-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 16px;
    border: none;
    border-radius: 4px;
    background: linear-gradient(180deg, #00C249 0%, #25741B 100%);
    font-family: 'Public Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      opacity: 0.9;
    }
  }
}

// Media queries for mobile
@media (max-width: 767px) {
  .interstitial-study-info-container {
    padding: 0;
    
    .interstitial-study-info-title {
      font-size: 18px;
    }
  }
}
