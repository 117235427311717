.loader-container {
  &.review {
    font-family: Inter, sans-serif;
    max-width: 544px;
    margin: 0 auto;
  }
}

.review-loader {
  border-radius: 16px;
  background-color: #fff;
  padding: 16px;
  margin: 0 auto;

  &__profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  &__image-wrapper {
    display: flex;
    align-items: center;

    .name {
      padding-left: 14px;
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;

      @media (max-width: 700px) {
        font-size: 16px;
      }
    }
  }

  .review-text {
    font-size: 18px;
    text-align: left;
    padding-bottom: 16px;
  }

  .review-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    .image-wrapper {
      max-width: 240px;

      img {
        width: 100%;
      }
    }
  }
}